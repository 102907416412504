@import "variables";
@import "libs/normalize";

@import "./components/all";

body {
  background-color: $background;
}

.App {
  text-align: center;
  height: 100%;
  margin-bottom: 30px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.search-icon {
  width: 25px;
  height: 25px;
  opacity: 0.3;
  position: absolute;
  right: 12px;
  top: 17px;
  
  .clear {
    
  }
  &:hover {
    opacity: 0.8;
    cursor: pointer;
   }
}


